<div id="navmenu" class="nav-md">
    <div class="container body">
      <div class="main_container">
        <div class="col-md-3 left_col">
          <div class="left_col scroll-view">
            <div class="navbar nav_title" style="border: 0;">
              <a class="site_title">
                <!-- <i class="fa fa-paw"></i>  -->
                <span>{{title}}</span></a>
            </div>

            <div class="clearfix"></div>

            <!-- menu profile quick info --> 
            <!-- <div class="profile clearfix">
              <div class="profile_pic">
                <img src="images/img.jpg" alt="..." class="img-circle profile_img">
              </div>
              <div class="profile_info">
                <span>Welcome,</span>
                <h2>John Doe</h2>
              </div>
            </div> -->
            <!-- /menu profile quick info -->

            <!-- <br /> -->

            <app-custom-nav></app-custom-nav>

            <!-- /menu footer buttons -->
            <!-- <div class="sidebar-footer hidden-small">
              <a data-toggle="tooltip" data-placement="top" title="Settings">
                <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="FullScreen">
                <span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="Lock">
                <span class="glyphicon glyphicon-eye-close" aria-hidden="true"></span>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="Logout" href="login.html">
                <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
              </a>
            </div> -->
            <!-- /menu footer buttons -->
          </div>
        </div>

        <!-- top navigation -->
        <div class="top_nav">
          <div class="nav_menu">
              <div class="nav toggle">
                <a id="menu_toggle"  (click)="toggleClicked1($event)"><i class="fa fa-bars"></i></a>
              </div>
              <nav class="nav navbar-nav">
              <ul class=" navbar-right">
                <li class="nav-item dropdown open" style="padding-left: 15px;" ngbDropdown>
                  <a href="javascript:;" class="user-profile dropdown-toggle" aria-haspopup="true" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    <img src="{{user.profile.photo}}" alt="">{{user.profile.fullname}}
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-usermenu pull-right" aria-labelledby="navbarDropdown">
                    @for (us of user_access; track us; let idx = $index) { 
                      <a class="dropdown-item"  >{{us.description}}</a>
                    }
                    <a class="dropdown-item"  (click)="backtoportal()">Portal</a>
                    <a class="dropdown-item"  (click)="changePassword()">Change Password</a>
                    
                    <a class="dropdown-item"  (click)="logout()"><i class="fa fa-sign-out pull-right"></i> Log Out</a>
                  </div>
                </li>

                <li role="presentation" class="nav-item dropdown open" ngbDropdown>
                  <a href="javascript:;" class="dropdown-toggle info-number" id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    <i class="fa fa-bell"></i>
                    @if ( this.notifs ){
                      @if (this.notifs.meta.totalCount > 0){
                        <span class="badge bg-green">{{this.notifs.meta.totalCount}}</span>
                      }
                    }
                  </a>
                  @if ( this.notifs ){
                    @if (this.notifs.meta.totalCount > 0){
                      <ul ngbDropdownMenu class="dropdown-menu list-unstyled msg_list scrollable-dropdown" role="menu" aria-labelledby="navbarDropdown1">
                        
                          @for (notif of this.notifs.data; track notif) {
                          <li class="nav-item">
                            <a class="dropdown-item" (click)="readNotif(notif)">
                              <!-- <span class="image"><img src="images/img.jpg" alt="Profile Image" /></span> -->
                              <span>
                                <span>{{notif.message}}</span>
                              </span>
                              <span class="message time">{{timeAgo(notif.created_at)}}</span>
                              
                            </a>
                          </li>
                        }
                      
                      </ul>
                    }@else {
                      <ul ngbDropdownMenu style="display: none;"></ul>
                    }
                }
                </li>
                
              </ul>
            </nav>
            
          </div>
        </div>
        <!-- /top navigation -->

    <!-- page content -->
    <div class="right_col" role="main" [style.min-height]="Rightcol" id="right_col">
        <router-outlet></router-outlet>
      </div>
      <!-- /page content -->

        <!-- footer content -->
        <footer>
          <div class="pull-right">
            Copyright © 2020 Intishaka Global Solusindo. All rights reserved.
          </div>
          <div class="clearfix"></div>
        </footer>
        <!-- /footer content -->
      </div>
    </div>
  </div>